import cx from "classnames";
import logo from "public/more-int.png";
import vector from "public/vector-int.png";
import React, { FC } from "react";
import { BsChevronDown } from "react-icons/bs";
import CtaButton from "./CtaButton";
import { featuresList, freeToolsList, industryList, integrationList, partnersList, resourcesList } from "./navItemData";
interface UnknownNavBarProps {
    handleLogin: (e: any) => void;
    open: boolean;
}

interface SubMenuComponentType {
    list: Array<{
        img: string;
        title: string;
        link: string;
        description: string;
    }>;
}

interface IntegrationSubMenuComponentType {
    list: Array<{
        img: string;
        title: string;
        link: string;
        description: string;
    }>;
}

const UnknownNavBar: FC<UnknownNavBarProps> = ({ open }) => {
    const itemsClassName = "lg:text-gray-600 text-black cursor-pointer my-6 md:mr-3 md:my-0 lg:font-normal font-medium";
    return (
        <nav
            className={cx(
                "flex-col md:flex-row flex-grow text-base md:text-sm items-start md:items-center pb-4 md:pb-0 px-4 md:px-0 md:flex md:justify-between shadow-lg md:shadow-none rounded-b-lg md:rounded-b-none nav hidden",
                { flex: open, hidden: !open }
            )}
        >
            <ul className="nav__menu flex">
                <li className="nav__menu-item">
                    <a href="" className={`${itemsClassName} flex items-center`}>
                        {" "}
                        Features
                        <BsChevronDown className="ml-1" />{" "}
                    </a>
                    <SubMenuComponent list={featuresList} />
                </li>

                <li className="nav__menu-item">
                    <a href="" className={`${itemsClassName} flex items-center`}>
                        {" "}
                        Industries
                        <BsChevronDown className="ml-1" />{" "}
                    </a>
                    <SubMenuComponent list={industryList} />
                </li>

                <li className="nav__menu-item">
                    <a href="/whatsapp-integrations" className={`${itemsClassName} flex items-center`}>
                        {" "}
                        Integrations
                        <BsChevronDown className="ml-1" />{" "}
                    </a>
                    <IntegrationSubMenuComponent list={integrationList} />
                </li>

                <li className="nav__menu-item ">
                    <a href="" className={`${itemsClassName} flex items-center`}>
                        {" "}
                        Resources
                        <BsChevronDown className="ml-1" />{" "}
                    </a>
                    <SubMenuComponent list={resourcesList} />
                </li>

                <li className="nav__menu-item">
                    <a href="" className={`${itemsClassName} inline-flex items-center`} style={{ marginRight: "8px" }}>
                        {" "}
                        Free Tools
                        <BsChevronDown className="ml-1" />
                    </a>
                    <VerticalSubMenuComponent list={freeToolsList} />
                </li>

                <li className="nav__menu-item ">
                    <a href="" className={`${itemsClassName} flex items-center`}>
                        {" "}
                        Partners
                        <BsChevronDown className="ml-1" />{" "}
                    </a>
                    <VerticalSubMenuComponent list={partnersList} />
                </li>
            </ul>

            <div className="flex items-center">
                <a href={"/pricing"} className={itemsClassName}>
                    Pricing
                </a>

                <a href="https://app.gallabox.com/login" target="_blank" rel="noreferrer" className="hover:no-underline">
                    Login{" "}
                </a>
                <CtaButton type="small" text={"Start Free Trial"} customClass={"ml-5 start-trial-btn"} />
            </div>
        </nav>
    );
};

const SubmenuItem = ({ image, link, title, description, isNew }: { image: string; link: string; title: string; description: string; isNew?: boolean }) => {
    return (
        <a href={link}>
            <div className="flex items-start gap-2 w-64 mx-auto p-2  submenu-item">
                <img src={image} alt={`${title} icon`} />
                <div>
                    <p className="font-medium text-base" style={{ letterSpacing: "0.03em", color: "#171923" }}>
                        {title} {isNew && <span className="new-tag">New</span>}
                    </p>
                    <p className="font-normal text-xs" style={{ letterSpacing: "0.03em", color: "#718096" }}>
                        {description}
                    </p>
                </div>
            </div>
            <style jsx>{`
                .submenu-item:hover {
                    background-color: #edf2f7;
                }
                .new-tag {
                    background-color: #25d366;
                    color: white;
                    font-size: 0.7em;
                    padding: 0.2em 0.5em;
                    border-radius: 3px;
                    margin-left: 5px;
                }
            `}</style>
        </a>
    );
};
const VerticalSubMenuComponent: FC<SubMenuComponentType> = ({ list }) => {
    return (
        <ul className="nav__submenu nav__submenu-resources">
            <div className="navbar__submenu_wrapper">
                <div className="nav__submenu_block_wrapper nav__submenu_block_wrapper-resources" style={{ paddingTop: "12px", paddingLeft: "20px", paddingRight: "16px" }}>
                    {/* <div className="flex flex-wrap gap-3 pb-2.5" style={{ width: "553px" }}> */}
                    {list.map((item: { title: string; img: string; description: string; link: string; isNew?: boolean }) => (
                        <SubmenuItem key={item.title} image={item.img} title={item.title} description={item.description} link={item.link} isNew={item.isNew} />
                    ))}
                    {/* </div> */}
                </div>
            </div>
        </ul>
    );
};
const SubMenuComponent: FC<SubMenuComponentType> = ({ list }) => {
    return (
        <ul className="nav__submenu nav__submenu-resources">
            <div className="navbar__submenu_wrapper">
                <div className="nav__submenu_block_wrapper nav__submenu_block_wrapper-resources">
                    <div className="flex flex-wrap gap-3 pb-2.5" style={{ width: "553px" }}>
                        {list.map((item: { title: string; img: string; description: string; link: string; isNew?: boolean }) => (
                            <SubmenuItem key={item.title} image={item.img} title={item.title} description={item.description} link={item.link} isNew={item.isNew} />
                        ))}
                    </div>
                </div>
            </div>
        </ul>
    );
};

const IntegrationSubMenuComponent: FC<IntegrationSubMenuComponentType> = ({ list }) => {
    return (
        <ul className="nav__submenu nav__submenu-resources">
            <div className="navbar__submenu_wrapper">
                <div className="nav__submenu_block_wrapper nav__submenu_block_wrapper-resources">
                    <div className="flex flex-wrap gap-3 pb-2.5" style={{ width: "553px" }}>
                        {list.map((item: { title: string; img: string; description: string; link: string; isNew?: boolean }) => (
                            <SubmenuItem key={item.title} image={item.img} title={item.title} description={item.description} link={item.link} isNew={item.isNew} />
                        ))}
                        <a href="/whatsapp-integrations" target="_blank" rel="noreferrer" className="pl-8 pt-2">
                            <div className=" w-40 h-12  rounded-lg space-y-2 bg-blue-900 hover:bg-blue-800">
                                <div className=" flex flex-row pt-2 pb-2">
                                    <img src={logo} className=" pl-2 pt-1 pb-1 w-8 h-8" alt="See more about Whatsapp Integrations"></img>
                                    <div className="text-white font-sans text-base font-medium pt-0.5 pl-2 pr-2">View All</div>
                                    <img src={vector} className=" w-5 h-5 pt-2" alt="Side Vector Icon"></img>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </ul>
    );
};

export default React.memo(UnknownNavBar);
