export const container_classname = "lg:max-w-7xl lg:mx-auto px-5 w-full";
export const sub_section_heading = "lg:text-6xl text-4xl font-semibold text-gray-900";

export interface ColumnItemsType {
    header: string;
    items: {
        name: string;
        link?: string;
        externalLink?: string;
        isNew?: boolean;
    }[];
}

export interface FooterDataProps {
    title: string;
    columnItems: ColumnItemsType[];
}

export const footerData1: FooterDataProps[] = [
    {
        title: "column1",
        columnItems: [
            {
                header: "Features",
                items: [
                    {
                        name: "WhatsApp Flows",
                        link: "/whatsapp-flows",
                    },
                    {
                        name: "WhatsApp AI Chatbot",
                        link: "/whatsapp-ai-chatbot",
                    },
                    {
                        name: "WhatsApp Shop",
                        link: "/build-your-shop-on-whatsapp",
                    },
                    {
                        name: "WhatsApp Business API",
                        link: "/whatsapp-business-api",
                    },

                    {
                        name: "WhatsApp Chatbot",
                        link: "/whatsapp-chatbot",
                    },
                    {
                        name: "WhatsApp Broadcast",
                        link: "/whatsapp-broadcast",
                    },
                    {
                        name: "Click to WhatsApp Ads",
                        link: "/click-to-whatsapp-ads",
                    },

                    {
                        name: "Shared Team Inbox",
                        link: "/whatsapp-shared-inbox",
                    },
                    {
                        name: "Drip Marketing",
                        link: "/whatsapp-drip-marketing",
                    },
                    {
                        name: "WhatsApp Payments",
                        link: "/whatsapp-payments",
                    },
                ],
            },
        ],
    },
    {
        title: "column2",
        columnItems: [
            {
                header: "Integration",
                items: [
                    {
                        name: "Calendly",
                        link: "/whatsapp-integrations/calendly",
                        isNew: true,
                    },
                    {
                        name: "Zoho CRM",
                        link: "/whatsapp-integrations/zoho-books",
                        isNew: true,
                    },
                    {
                        name: "LeadSquared",
                        link: "/whatsapp-integrations/lead-squared",
                    },
                    {
                        name: "Odoo",
                        link: "/whatsapp-integrations/odoo",
                        isNew: true,
                    },
                    {
                        name: "MoEngage",
                        link: "/whatsapp-integrations/moengage",
                    },
                    {
                        name: "Shopify",
                        link: "/whatsapp-integrations/shopify",
                    },
                    {
                        name: "Zapier ",
                        link: "/whatsapp-integrations/zapier",
                    },
                    {
                        name: "Hubspot ",
                        link: "/whatsapp-integrations/hubspot",
                    },
                    {
                        name: "RazorPay",
                        link: "/whatsapp-integrations/razorpay",
                    },
                    {
                        name: "Google Sheets",
                        link: "/whatsapp-integrations/google-sheets",
                    },
                ],
            },
        ],
    },
    {
        title: "column3",
        columnItems: [
            {
                header: "Industry",
                items: [
                    {
                        name: "Education",
                        link: "/industry/education/whatsapp",
                    },
                    {
                        name: "Travel",
                        link: "/industry/travel/whatsapp",
                    },
                    {
                        name: "Real Estate",
                        link: "/industry/real-estate/whatsapp",
                        isNew: true,
                    },
                    {
                        name: "Finance",
                        link: "/industry/finance/whatsapp",
                    },
                    {
                        name: "Healthcare",
                        link: "/industry/healthcare/whatsapp",
                    },
                    {
                        name: "ecommerce",
                        link: "/industry/ecommerce/whatsapp",
                    },
                ],
            },
        ],
    },
    {
        title: "column4",
        columnItems: [
            {
                header: "Compare",
                items: [
                    {
                        name: "with Wati",
                        link: "/best-wati-alternative",
                    },
                    {
                        name: "with Aisensy",
                        link: "/best-aisensy-alternative",
                    },
                    {
                        name: "with Rasayel",
                        link: "/best-rasayel-alternative",
                    },
                    {
                        name: "with Zoko",
                        link: "/zoko-alternative",
                    },
                    {
                        name: "with Verloop",
                        link: "/verloop-alternative",
                    },
                    {
                        name: "with Interakt",
                        link: "/interakt-alternative",
                    },
                    {
                        name: "with Infobip",
                        link: "/infobip-alternative",
                    },
                    {
                        name: "with GetChat",
                        link: "/best-getchat-alternative",
                    },
                ],
            },
        ],
    },
    {
        title: "column5",
        columnItems: [
            {
                header: "Resources",
                items: [
                    {
                        name: "Documentation",
                        externalLink: "https://docs.gallabox.com/",
                    },
                    {
                        name: "Developer APIs",
                        externalLink: "https://documenter.getpostman.com/view/17087523/Tzz7QJTJ",
                    },
                    {
                        name: "Blog",
                        externalLink: "/blog",
                    },
                    {
                        name: "eBooks",
                        externalLink: "/resources/downloadables",
                    },
                    {
                        name: "Videos",
                        externalLink: "https://www.youtube.com/c/Gallabox/videos",
                    },
                    {
                        name: "Case Study",
                        externalLink: "/case-study",
                    },
                    {
                        name: "How-To Guides",
                        externalLink: "https://guides.gallabox.com/",
                    },
                    {
                        name: "Newsroom",
                        externalLink: "/news",
                    },
                ],
            },
        ],
    },
    {
        title: "column6",
        columnItems: [
            {
                header: "Company",
                items: [
                    {
                        name: "About",
                        link: "/about",
                    },
                    {
                        name: "Customers",
                        link: "/case-study",
                    },
                    {
                        name: "Careers",
                        externalLink: "https://angel.co/company/gallabox",
                    },
                    {
                        name: "Contact Us",
                        link: "/contact",
                    },
                    {
                        name: "Partner with us",
                        externalLink: "/gallabox-partner-program",
                        isNew: true,
                    },
                ],
            },
            {
                header: "Support",
                items: [
                    {
                        name: "FAQ",
                        externalLink: "https://docs.gallabox.com/?q=",
                    },
                    {
                        name: "Raise a ticket",
                        externalLink: "https://gallaboxsupport.zohodesk.in/portal/en/home",
                    },
                ],
            },
        ],
    },
];

export const mobileFooterData: FooterDataProps[] = [
    {
        title: "column1",
        columnItems: [
            {
                header: "Features",
                items: [
                    {
                        name: "WhatsApp Flows",
                        link: "/whatsapp-flows",
                    },
                    {
                        name: "WhatsApp AI Chatbot",
                        link: "/whatsapp-ai-chatbot",
                    },
                    {
                        name: "WhatsApp Shop",
                        link: "/build-your-shop-on-whatsapp",
                    },
                    {
                        name: "WhatsApp Business API",
                        link: "/whatsapp-business-api",
                    },

                    {
                        name: "WhatsApp Chatbot",
                        link: "/whatsapp-chatbot",
                    },
                    {
                        name: "WhatsApp Broadcast",
                        link: "/whatsapp-broadcast",
                    },
                    {
                        name: "Click to WhatsApp Ads",
                        link: "/click-to-whatsapp-ads",
                    },

                    {
                        name: "Shared Team Inbox",
                        link: "/whatsapp-shared-inbox",
                    },
                    {
                        name: "Drip Marketing",
                        link: "/whatsapp-drip-marketing",
                    },
                    {
                        name: "WhatsApp Payments",
                        link: "/whatsapp-payments",
                    },
                ],
            },
        ],
    },
    {
        title: "column2",
        columnItems: [
            {
                header: "Integration",
                items: [
                    {
                        name: "Calendly",
                        link: "/whatsapp-integrations/calendly",
                        isNew: true,
                    },
                    {
                        name: "Zoho CRM",
                        link: "/whatsapp-integrations/zoho-books",
                        isNew: true,
                    },
                    {
                        name: "Odoo",
                        link: "/whatsapp-integrations/odoo",
                        isNew: true,
                    },
                    {
                        name: "MoEngage",
                        link: "/whatsapp-integrations/moengage",
                    },
                    {
                        name: "Shopify",
                        link: "/whatsapp-integrations/shopify",
                    },
                    {
                        name: "Zapier ",
                        link: "/whatsapp-integrations/zapier",
                    },
                    {
                        name: "Hubspot ",
                        link: "/whatsapp-integrations/hubspot",
                    },
                    {
                        name: "RazorPay",
                        link: "/whatsapp-integrations/razorpay",
                    },
                    {
                        name: "Google Sheets",
                        link: "/whatsapp-integrations/google-sheets",
                    },
                ],
            },
        ],
    },
    {
        title: "column3",
        columnItems: [
            {
                header: "Industry",
                items: [
                    {
                        name: "Education",
                        link: "/industry/education/whatsapp",
                    },
                    {
                        name: "Travel",
                        link: "/industry/travel/whatsapp",
                    },
                    {
                        name: "Real Estate",
                        link: "/industry/ecommerce/whatsapp",
                        isNew: true,
                    },
                    {
                        name: "Finance",
                        link: "/industry/finance/whatsapp",
                    },
                    {
                        name: "Healthcare",
                        link: "/industry/healthcare/whatsapp",
                    },
                    {
                        name: "ecommerce",
                        link: "/industry/ecommerce/whatsapp",
                    },
                ],
            },
        ],
    },
    {
        title: "column4",
        columnItems: [
            {
                header: "Compare",
                items: [
                    {
                        name: "with Wati",
                        link: "/best-wati-alternative",
                    },
                    {
                        name: "with Aisensy",
                        link: "/best-aisensy-alternative",
                    },
                    {
                        name: "with Rasayel",
                        link: "/best-rasayel-alternative",
                    },
                    {
                        name: "with Zoko",
                        link: "/zoko-alternative",
                    },
                    {
                        name: "with Verloop",
                        link: "/verloop-alternative",
                    },
                    {
                        name: "with Interakt",
                        link: "/interakt-alternative",
                    },
                    {
                        name: "with Infobip",
                        link: "/infobip-alternative",
                    },
                    {
                        name: "with GetChat",
                        link: "/best-getchat-alternative",
                    },
                ],
            },
        ],
    },
    {
        title: "column5",
        columnItems: [
            {
                header: "Resources",
                items: [
                    {
                        name: "Documentation",
                        externalLink: "https://docs.gallabox.com/",
                    },
                    {
                        name: "Developer APIs",
                        externalLink: "https://documenter.getpostman.com/view/17087523/Tzz7QJTJ",
                    },
                    {
                        name: "Blog",
                        externalLink: "/blog",
                    },
                    {
                        name: "eBooks",
                        externalLink: "/resources/downloadables",
                    },
                    {
                        name: "Videos",
                        externalLink: "https://www.youtube.com/c/Gallabox/videos",
                    },
                    {
                        name: "Case Study",
                        externalLink: "/case-study",
                    },
                    {
                        name: "How-To Guides",
                        externalLink: "https://guides.gallabox.com/",
                    },
                    {
                        name: "News room",
                        externalLink: "/news",
                    },
                ],
            },
        ],
    },
    {
        title: "column6",
        columnItems: [
            {
                header: "Company",
                items: [
                    {
                        name: "About",
                        link: "/about",
                    },
                    {
                        name: "Customers",
                        link: "/case-study",
                    },
                    {
                        name: "Careers",
                        externalLink: "https://angel.co/company/gallabox",
                    },
                    {
                        name: "Contact Us",
                        link: "/contact",
                    },
                    {
                        name: "Partner with us",
                        externalLink: "/gallabox-partner-program",
                        isNew: true,
                    },
                ],
            },
        ],
    },
    {
        title: "column7",
        columnItems: [
            {
                header: "Support",
                items: [
                    {
                        name: "FAQ",
                        externalLink: "https://docs.gallabox.com/?q=",
                    },
                    {
                        name: "Raise a ticket",
                        externalLink: "https://gallaboxsupport.zohodesk.in/portal/en/home",
                    },
                ],
            },
        ],
    },
];

export const mohitCalender = "https://calendly.com/mohit-sharma-gallabox/partnerships";
