import { container_classname, footerData1, FooterDataProps, mobileFooterData } from "@components/new-ui/constants";
import Image from "next/image";
import React from "react";
import { FaFacebookSquare, FaInstagramSquare, FaLinkedin, FaWhatsappSquare, FaYoutubeSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { MdArrowOutward } from "react-icons/md";

interface DesktopFooterProps {
    footerDatas: FooterDataProps[];
    handleClickDesktop: (data: any) => FooterLinkProps;
}

const DesktopFooter: React.FC<DesktopFooterProps> = (props) => {
    const { footerDatas, handleClickDesktop } = props;
    return (
        <div className="w-full flex flex-row gap-5">
            {footerDatas.map((footerData) => {
                const { title, columnItems } = footerData;
                return (
                    <div key={title} className="flex flex-col gap-5 w-1/4">
                        {columnItems.map((columnItems) => (
                            <>
                                <div className="text-xl text-gray-100 font-semibold">{columnItems.header}</div>
                                <div className="flex flex-col gap-4 pb-6">
                                    {columnItems.items.map((linkData: any) => {
                                        return (
                                            <a key={linkData.name} className="cursor-pointer text-gray-400" {...handleClickDesktop(linkData)}>
                                                {linkData.name}
                                                {linkData.isNew && (
                                                    <span className="text-xs ml-3 px-2 py-1 rounded-2xl text-white font-semibold" style={{ background: "#3364FA" }}>
                                                        NEW
                                                    </span>
                                                )}
                                            </a>
                                        );
                                    })}
                                </div>
                            </>
                        ))}
                    </div>
                );
            })}
        </div>
    );
};

interface MobileFooterProps {
    footerDatas: FooterDataProps[];
    handleClickMobile: (data: any) => void;
}

const MobileFooter: React.FC<MobileFooterProps> = (props) => {
    const { footerDatas, handleClickMobile } = props;
    return (
        <div className="flex flex-col">
            {footerDatas.map((footerData) => {
                const { title, columnItems } = footerData;
                return (
                    <div key={title} className="tab w-full overflow-hidden px-7 py-4 border-b border-solid border-gray-800">
                        {columnItems.map((columnItems) => (
                            <>
                                <input className="absolute hidden" id={`${columnItems.header}-menu`} type="checkbox" name="Company" />
                                <label htmlFor={`${columnItems.header}-menu`} className="flex items-center justify-between">
                                    <div className="text-lg font-semibold text-white">{columnItems.header}</div>
                                    <MdArrowOutward size="24px" color="white" className="expand-toggle-icon" />
                                </label>
                                <div className="tab-content overflow-hidden">
                                    <div className="flex flex-col gap-7 text-sm py-4">
                                        {columnItems.items.map((linkData: any) => (
                                            <a key={linkData.name} className="text-gray-400" onClick={() => handleClickMobile(linkData)}>
                                                {linkData.name}
                                                {linkData.isNew && (
                                                    <span className="text-xs ml-2 px-2 py-1 rounded-2xl text-white" style={{ background: "#3364FA" }}>
                                                        New
                                                    </span>
                                                )}
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                );
            })}
        </div>
    );
};

export type FooterLinkProps = { href: string; "data-href": string; target?: string; onClick?: never } | { onClick: () => void; href?: never; "data-href"?: never; target?: never };

const Footer: React.FC = () => {
    const handleClickMobile = (linkData: any) => {
        if (linkData?.externalLink) {
            window.open(linkData?.externalLink, "_blank");
        } else if (linkData?.link) {
            window.open(linkData?.link);
        }
    };

    const handleClickDesktop = (linkData: any): FooterLinkProps => {
        if (linkData?.externalLink) {
            return { href: linkData?.externalLink, "data-href": linkData?.externalLink, target: "_blank" };
        } else if (linkData?.link) {
            return { href: linkData?.link, "data-href": linkData?.link };
        }
        return { href: "", "data-href": "", target: undefined, onClick: undefined };
    };

    return (
        <footer className="bg-black text-gray-100">
            <div className="w-full" style={{ background: "linear-gradient(90deg, #0E1018 0%, #282A30 100%)" }}>
                <div className={`${container_classname} flex lg:flex-row flex-col items-center justify-center lg:gap-14 gap-3 text-xl font-medium text-white lg:py-4 py-5`}>
                    <Image width={220} height={20} src="https://assets.gallabox.com/gb-home/new-landing/footer/meta-business-partner.png" alt="meta partner logo" />

                    <p className="lg:block hidden">|</p>
                    <p className="lg:text-base text-sm">Powered by WhatsApp Business API</p>
                </div>
            </div>
            <div className={`hidden lg:block ${container_classname} pt-20 pb-12 `}>
                <div className="flex flex-col gap-16">
                    <DesktopFooter footerDatas={footerData1} handleClickDesktop={handleClickDesktop} />
                </div>
            </div>

            <div className="lg:hidden flex flex-col w-full">
                <MobileFooter footerDatas={mobileFooterData} handleClickMobile={handleClickMobile} />
            </div>

            <div className={`${container_classname} border-b border-solid border-gray-700 flex lg:justify-between lg:flex-row flex-col gap-20 lg:pt-0 pt-16 lg:pb-20 pb-16`}>
                <div className="w-64 flex flex-col text-inherit text-sm ">
                    <div className="my-0 font-medium tracking-wider text-xl">Download the App</div>
                    <div className="flex items-center justify-center lg:justify-start">
                        <a className="track_applestore" target="_blank" rel="noreferrer" href="https://apps.apple.com/in/app/gallabox/id1542957177">
                            <Image loading="lazy" src="/appstore-badge.svg" alt="Download on the App Store" width={132} height={53} />
                        </a>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://play.google.com/store/apps/details?id=com.mangoleap.gallabox&utm_source=Landing%20Page"
                            className="track_playstore ml-2"
                        >
                            <Image
                                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                                alt="Get it on Google Play"
                                width={170}
                                height={67}
                            />
                        </a>
                    </div>
                </div>
                <div className="flex flex-col justify-start gap-3">
                    <div className="my-0 font-semibold tracking-wider text-xl leading-5">Connect with us</div>
                    <div className="flex gap-4 text-transparent text-gray-400">
                        <a target="_blank" rel="noreferrer" href="https://www.facebook.com/gallaboxofficial" aria-label="Facebook">
                            <FaFacebookSquare size={36} />
                        </a>
                        <a target="_blank" rel="noreferrer" href="https://www.instagram.com/gallabox" aria-label="Instagram">
                            <FaInstagramSquare size={36} />
                        </a>
                        <a target="_blank" rel="noreferrer" href="https://twitter.com/gallabox" aria-label="Twitter">
                            <FaSquareXTwitter size={36} />
                        </a>
                        <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/showcase/gallabox-crm" aria-label="Linkedin">
                            <FaLinkedin size={36} />
                        </a>
                        <a target="_blank" rel="noreferrer" href="https://www.youtube.com/c/Gallabox/videos" aria-label="Youtube">
                            <FaYoutubeSquare size={36} />
                        </a>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://api.whatsapp.com/send/?phone=%2B917825877730&text=Hello+Gallabox+Sales+team+&type=phone_number&app_absent=0"
                            aria-label="WhatsApp"
                        >
                            <FaWhatsappSquare size={36} />
                        </a>
                    </div>
                </div>
            </div>

            <div className="lg:hidden block text-8xl font-bold text-white text-center py-10" style={{ fontFamily: "Degular Display" }}>
                Gallabox
            </div>

            <div className={`lg:block hidden ${container_classname} py-20`}>
                <Image width={1215} height={215} src="https://assets.gallabox.com/gb-home/new-landing/footer/gallabox-name.png" alt="gallabox" />
            </div>

            <div className="py-6" style={{ backgroundColor: "#0E1018" }}>
                <div className={`${container_classname} flex lg:justify-between lg:flex-row flex-col gap-4`}>
                    <span className="text-white text-sm tracking-wide lg:text-left text-center lg:w-full w-64 lg:mx-0 mx-auto">
                        © {new Date().getFullYear()} Gallabox Global Inc. All rights reserved.
                    </span>
                    <div className="w-full flex flex-row gap-5 text-sm text-gray-200 lg:justify-end justify-center">
                        <a target="_blank" rel="noreferrer" href="/cookie-policy" data-href="/cookie-policy">
                            Cookie Policy
                        </a>
                        <a target="_blank" rel="noreferrer" href="/privacy-policy" data-href="/privacy-policy">
                            Privacy Policy
                        </a>
                        <a target="_blank" rel="noreferrer" href="/terms" data-href="/terms">
                            Terms & Conditions
                        </a>
                    </div>
                </div>
            </div>

            <style jsx>{`
                .tab-content {
                    padding-top: 0.625rem;
                    max-height: 0.625rem;
                    display: none;

                    -webkit-transition: all 200ms ease-in;
                    -o-transition: all 200ms ease-in;
                    transition: all 200ms ease-in;
                }

                .tab input:checked ~ .tab-content {
                    display: block !important;
                    max-height: 100vh;
                    padding-top: 0 !important;
                }

                .tab input + label {
                    transition: all 200ms ease-in;
                    font-weight: 400;
                }

                .tab input + label:hover {
                    color: #333333;
                }

                .tab input:checked + label {
                    color: #000;
                    font-weight: 500;
                }
            `}</style>
        </footer>
    );
};

export default React.memo(Footer);
